import {
  put,
  call,
  CallEffect,
  PutEffect,
  takeLatest,
} from "redux-saga/effects";
import { ping, pong } from "./app.slice";
import { appService } from "./app.service";

function* appInit(): Generator<PutEffect | CallEffect> {
  try {
    yield call(appService.init);
    yield put(pong());
  } catch (e: any) {}
}

export function* watchAppInit() {
  yield takeLatest(ping, appInit);
}
