import { all, fork } from "redux-saga/effects";
import { watchAppInit } from "../components/app/app.saga";
import {watchItemsRequest} from "../components/Item/Item.saga";

export default function* rootSaga() {
  yield all([
    fork(watchAppInit),
    fork(watchItemsRequest)
  ]);
}
