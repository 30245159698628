import React from "react";
import { Card, Elevation, H5 } from "@blueprintjs/core";
import "./Item.styles.css";
import { CakeInterface } from "./Item.initialState";

const Item = (props: CakeInterface) => (
  <Card className={"item-card"} interactive={false} elevation={Elevation.ONE}>
    <div className={"item-img"}>
      <img
        src={"https://diana-cakes172742-staging.s3.amazonaws.com/public/items/" + props.img}
        alt={props.name}
        style={{ width: "100%", marginBottom: "10px", borderRadius: "5px" }}
      />
    </div>
    <H5>
      <div style={{ textDecoration: "none", color: "#215db0" }}>
        {props.name} - ${props.price}/{props.per}
        {props.per === "kg"
          ? " ($" + Math.ceil(props.price / 2.2) + "/lb)"
          : null}
      </div>
    </H5>
    {props.description ? (
      <p>
        <b>Description:</b> {props.description}
      </p>
    ) : null}
    {props.ingredients ? (
      <p>
        <b>Ingredients:</b> {props.ingredients}
      </p>
    ) : null}
  </Card>
);
export default Item;
