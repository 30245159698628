import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./Item.initialState";
import { RootState } from "../../app/store";

export const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    getAllItemsRequest: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    getAllItemsSuccess: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
  },
});

export const allItems = (state: RootState) => state.items;

export const { getAllItemsRequest, getAllItemsSuccess } =
  itemSlice.actions;
export default itemSlice.reducer;
