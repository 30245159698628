// @flow
import * as React from "react";
import { Outlet } from "react-router-dom";
import {
  Alignment,
  Button,
  Navbar,
  NavbarHeading,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { allItems } from "../Item/Item.slice";
import { MainMenu } from "./MainMenu";
import { toggleMenu } from "../app/app.slice";

type Props = {};
export const AppLayout = (props: Props) => {
  const items = useAppSelector(allItems);
  const dispatch = useAppDispatch();
  return (
    <>
      <MainMenu />
      <div className="App">
        <Navbar fixedToTop={true} className={"bp4-dark"}>
          <Navbar.Group align={Alignment.LEFT}>
            <NavbarHeading>
              {items.isLoading ? (
                <div>
                  <Spinner size={SpinnerSize.SMALL} />
                </div>
              ) : (
                "Cakes by Diana.MA"
              )}
            </NavbarHeading>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button
              className="bp4-minimal"
              icon="menu"
              onClick={() => dispatch(toggleMenu())}
            />
          </Navbar.Group>
        </Navbar>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
