import React from "react"; // , { useEffect }
import "./App.css";
//import { useAppDispatch } from "./app/hooks";
//import { ping } from "./components/app/app.slice";
import { Items } from "./components/Item/Items";
import { Routes, Route } from "react-router-dom";
import { AppLayout } from "./components/Layout/AppLayout";
import { NoMatch } from "./components/Layout/NoMatch";
import { Contacts } from "./components/Layout/Contacts";
//import { getAllItemsRequest } from "./components/Item/Item.slice";

function App() {
  //const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(ping());
  //   dispatch(getAllItemsRequest());
  // }, [dispatch]);
  return (
    <>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Items />} />
          <Route path="contacts" element={<Contacts />} />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
