// @flow
import * as React from "react";
import { Drawer, Icon, Position } from "@blueprintjs/core";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isMenuOpen, toggleMenu } from "../app/app.slice";
import { NavLink } from "react-router-dom";

export interface IDrawerExampleState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  hasBackdrop: boolean;
  position?: Position;
  size: string;
  title: string;
  usePortal: boolean;
}
type Props = {};
export const MainMenu = (props: Props) => {
  let state: IDrawerExampleState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    hasBackdrop: true,
    position: Position.RIGHT,
    size: "240px",
    title: "Cakes by Diana.MA",
    usePortal: true,
  };
  const isOpen = useAppSelector(isMenuOpen);
  const dispatch = useAppDispatch();
  return (
    <div>
      <Drawer
        className={"bp4-dark"}
        onClose={() => dispatch(toggleMenu())}
        isOpen={isOpen}
        {...state}
      >
        <div style={{ margin: "5px" }}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "bp4-button bp4-large bp4-active bp4-minimal"
                : "bp4-button bp4-large"
            }
            style={{ width: "100%", marginBottom: "5px" }}
            role="button"
            onClick={() => dispatch(toggleMenu())}
          >
            <Icon icon={"home"} /> <span>Home</span>
          </NavLink>
          <NavLink
            to="/contacts"
            className={({ isActive }) =>
              isActive
                ? "bp4-button bp4-large bp4-active bp4-minimal"
                : "bp4-button bp4-large"
            }
            style={{ width: "100%", marginBottom: "5px" }}
            role="button"
            onClick={() => dispatch(toggleMenu())}
          >
            <Icon icon={"phone"} /> <span>Contacts</span>
          </NavLink>
        </div>
      </Drawer>
    </div>
  );
};
