// @flow
import * as React from "react";
import Item from "./Item";
import { useAppSelector } from "../../app/hooks";
import { allItems } from "./Item.slice";

type Props = {};
export const Items = (props: Props) => {
  const items = useAppSelector(allItems);
  return (
    <div className={"items"}>
      {items.data.map((item) => (
        <Item
          key={item.itemId}
          itemId={item.itemId}
          name={item.name}
          ingredients={item.ingredients}
          description={item.description}
          price={item.price}
          img={item.img}
          sortBy={item.sortBy}
          per={item.per}
        />
      ))}
    </div>
  );
};
