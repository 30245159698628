import { combineReducers } from "redux";
import appReducer from "../components/app/app.slice";
import itemReducer from "../components/Item/Item.slice";

const rootReducer = combineReducers({
  app: appReducer,
  items: itemReducer,
});

export default rootReducer;
