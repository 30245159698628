import {
  put,
  call,
  CallEffect,
  PutEffect,
  SelectEffect,
  takeLatest,
} from "redux-saga/effects";
import {getAllItemsRequest, getAllItemsSuccess} from "./Item.slice";
import { itemService } from "./Item.service";

function* getAllCakesWorker(): Generator<
  PutEffect | SelectEffect | CallEffect
  > {
  const items: any = yield call(itemService.getAllCakes);
  yield put(getAllItemsSuccess(items.data));
}

export function* watchItemsRequest() {
  yield takeLatest(getAllItemsRequest, getAllCakesWorker);
}
