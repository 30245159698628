// @flow 
import * as React from 'react';
import { Link } from "react-router-dom";

type Props = {
  
};
export const NoMatch = (props: Props) => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};