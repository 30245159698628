import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AppInterface {
  isLoading: boolean;
  isMenuOpen: boolean;
  topBarTheme: "light" | "dark";
}

const initialState: AppInterface = {
  isLoading: false,
  isMenuOpen: false,
  topBarTheme: "dark",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeTopBarTheme: (state) => {
      state.topBarTheme = state.topBarTheme === "dark" ? "light" : "dark";
    },
    ping: (state) => {
      state.isLoading = true;
    },
    pong: (state) => {
      state.isLoading = false;
    },
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
  },
});

export const getTopBarTheme = (state: RootState) => state.app.topBarTheme;
export const isMenuOpen = (state: RootState) => state.app.isMenuOpen;

export const { changeTopBarTheme, ping, pong, toggleMenu } = appSlice.actions;
export default appSlice.reducer;
