// @flow
import * as React from "react";
import { Icon } from "@blueprintjs/core";

type Props = {};
export const Contacts = (props: Props) => {
  return (
    <div className={"items contacts-container"}>
      <a
        href={"https://www.instagram.com/cakesbydiana.ma/"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        <div className="bp4-card bp4-interactive bp4-elevation-0 contacts-icon">
          <Icon icon={"shop"} size={40} />
          <h4 className="bp4-heading">Instagram</h4>
        </div>
      </a>

      <a
        href={"https://www.facebook.com/cakesbydiana.ma/"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        <div className="bp4-card bp4-interactive bp4-elevation-0 contacts-icon">
          <Icon icon={"shop"} size={40} />
          <h4 className="bp4-heading">Facebook</h4>
        </div>
      </a>

      <a
        href={"mailto:cakesbydiana.ma@gmail.com"}
        target={"_blank"}
        rel={"noreferrer"}
      >
        <div className="bp4-card bp4-interactive bp4-elevation-0 contacts-icon">
          <Icon icon={"envelope"} size={40} />
          <h4 className="bp4-heading">Email</h4>
        </div>
      </a>

      <a href={"tel:1401-662-8683"} target={"_blank"} rel={"noreferrer"}>
        <div className="bp4-card bp4-interactive bp4-elevation-0 contacts-icon">
          <Icon icon={"mobile-phone"} size={40} />
          <h4 className="bp4-heading">Call</h4>
        </div>
      </a>
    </div>
  );
};
