export interface CakeInterface {
  itemId: number;
  name: string;
  img: string;
  price: number;
  description: string | null;
  ingredients: string | null;
  per: string;
  sortBy: number;
}

export interface CakesInitialState {
  isLoading: boolean;
  nextBatchUrl: string;
  data: CakeInterface[];
}

export const initialState: CakesInitialState = {
  isLoading: false,
  nextBatchUrl: "",
  data: [
    {
      itemId: 1,
      name: "Choco Sponge with raspberry or strawberry confit",
      img: "raspberry_choco_sponge.jpg",
      price: 55.0,
      description: "Soft Choco Sponge with raspberry or strawberry confit.",
      ingredients:
        "Cream cheese, eggs, raspberry, mascarpone, whipping cream, sugar, flour, cacao.",
      per: "kg",
      sortBy: 100,
    },
    {
      itemId: 3,
      name: "Blueberry Honey Cake",
      img: "blueberry-honey.jpg",
      price: 55.0,
      description:
        "The blueberries combined with lemon juice give it a slightly sour taste, which cuts through the sweetness. It looks good, too. The cake has 10 layers and 5 layers of blueberry filling.",
      ingredients:
        "Blueberry, cream cheese, whipping cream, flour, butter, sugar, sour cream, eggs, honey, lemon, sour cream.",
      per: "kg",
      sortBy: 300,
    },
    {
      itemId: 4,
      name: "Drunk Cherry Honey Cake",
      img: "drunk-cheery2.jpg",
      price: 60.0,
      description:
        "Honey chocolate layers, sour cream frosting, tart cherries soaked in whiskey - this is a Drunk Cherry Honey Cake.",
      ingredients:
        "Sour cherries, Mascarpone, whipping cream, sugar, cream cheese, flour, eggs, sour cream, butter, honey, cacao powder, whiskey.",
      per: "kg",
      sortBy: 400,
    },
    {
      itemId: 5,
      name: "Raspberry Honey Cake",
      img: "raspberry-vertical2.jpg",
      price: 65.0,
      description:
        "Raspberry Honey Cake has chocolate layers that are covered with raspberry marmalade and sour cream frosting.",
      ingredients:
        "Raspberry, sour cream, butter, flour, whipping cream, milk chocolate, cacao powder, honey, mascarpone, cream cheese, sugar.",
      per: "kg",
      sortBy: 500,
    },
    {
      itemId: 6,
      name: "Chocolate Honey Cake",
      img: "chocolate-honey2.jpg",
      price: 55.0,
      description: "",
      ingredients:
        "Unbleached flour, whipping cream, mascarpone, Philadelphia, sour cream, sugar, honey, Belgian dark chocolate Callebaut, butter, eggs, milk, cacao powder Cacao Barry, baking soda, vanilla paste.",
      per: "kg",
      sortBy: 550,
    },
    {
      itemId: 7,
      name: "Mega Chocolate Honey Cake",
      img: "spartak2.jpg",
      price: 60.0,
      description:
        "Mega Chocolate Honey Cake also called Spartak it's a delicious cake with sour cherries and it's vertical assembled.",
      ingredients:
        "Sour cherries, milk, honey, dark chocolate, whipping cream, eggs, butter, flour, sugar, cacao powder, vanilla pod, sour cream.",
      per: "kg",
      sortBy: 600,
    },
    {
      itemId: 8,
      name: "Classic Honey Cake",
      img: "honey-cake.jpg",
      price: 55.0,
      description:
        "Classic Honey Cake it's delicious, it has a rich taste and the frosting is similar to vanilla ice cream.",
      ingredients:
        "Cream cheese, sour cream, whipping cream, butter, eggs, flour, honey, sugar, vanilla paste.",
      per: "kg",
      sortBy: 700,
    },
    // {
    //   itemId: 9,
    //   name: "Snickers Honey Cake",
    //   img: "snickers-honey.jpg",
    //   price: 65.0,
    //   description:
    //     "Snickers Honey Cake has chocolate layers of honey cake, caramel-peanut frosting & chocolate-peanut frosting, homemade caramel and of course peanuts. The best option for covering a Snickers cake is milk chocolate ganache made with one of the best Belgian chocolate - Callebaut.",
    //   ingredients:
    //     "Milk chocolate, peanuts, butter, flour, eggs, sour cream, honey, whipping cream, sugar, cream cheese, vanilla paste, peanut paste, honey, cacao powder.",
    //   per: "kg",
    //   sortBy: 800,
    // },
    {
      itemId: 10,
      name: "Napoleon Classic",
      img: "napoleon2.jpg",
      price: 60.0,
      description:
        "This Napoleon is so good! I use organic eggs, milk and butter, and decorate it with organic fruit. It really does make a difference. This cake is also the one with the least amount of sugar, even though you can't tell by its decadent and rich taste.",
      ingredients: "Eggs, butter, sour cream, flour, milk, sugar, vanilla pod.",
      per: "kg",
      sortBy: 900,
    },
    // {
    //   itemId: 11,
    //   name: "Sunrise",
    //   img: "sunrise.jpg",
    //   price: 55.0,
    //   description:
    //     "Choco Sponge with baked cheesecake, Belgian milk chocolate mousse, homemade caramel, cashews or another nuts, a chocolate crunchy layer. It's a rich cake!",
    //   ingredients:
    //     "Cream cheese, whipping cream, cashews, caramel, flour, eggs, sour cream, milk, sugar, cacao powder, butter, milk chocolate, feuilletine flakes.",
    //   per: "kg",
    //   sortBy: 1000,
    // },
    {
      itemId: 12,
      name: "Carrot Cake",
      img: "carrot-cake.jpg",
      price: 55.0,
      description:
        "Carrot Cake? No problem! It took me a moment to wrap my head around it, but once I tried it, I understood why it's so popular. This one might just be the best one I've ever had. Turns out carrot sponge and orange moose is a great combination.",
      ingredients:
        "Philadelphia, unbleached all purpose flour, carrots, sugar, whipping cream, avocado oil, walnuts, eggs, orange juice, condensed milk, belgian white chocolate Callebaut, cacao powder Cacao Barry, baking soda, baking powder, cinnamon, gelatin, lemon zest, Vanilla paste.",
      per: "kg",
      sortBy: 1100,
    },
    // {
    //   itemId: 13,
    //   name: "Mint, Lime & Strawberry",
    //   img: "mint-lime.jpg",
    //   price: 55.0,
    //   description:
    //     "This is a mint, lime and strawberry cake decorated with organic fruits and Belgian chocolate. No food colouring was added. The cake is rich, yet refreshing. You should try it!",
    //   ingredients:
    //     "Butter, flour, eggs, yogurt, mint, lime, strawberry, mascarpone, whipping cream, sugar, cream cheese, gelatine.",
    //   per: "kg",
    //   sortBy: 1200,
    // },
    {
      itemId: 13,
      name: "Honey Cake with raspberry coulis and raspberry soufflé",
      img: "raspberry-souffle-cake.jpg",
      price: 60.0,
      description: "",
      ingredients:
        "Sour cream, raspberry puree, whipping cream, cream cheese Philadelphia, sugar, honey, butter, all purpose flour, eggs, cacao powder, baking soda, gelatin, pectin NH, salt.",
      per: "kg",
      sortBy: 1200,
    },
    {
      itemId: 14,
      name: "Vanilla Sponge",
      img: "vanilla-sponge.jpg",
      price: 55.0,
      description:
        "Vanilla Sponge is very soft, moist, the cream has a delicate note of vanilla. Strawberry confit it's a good choice for this cake.",
      ingredients:
        "Butter, eggs, flour, sugar, milk, mascarpone, whipping cream, cream cheese vanilla pod, strawberry purée.",
      per: "kg",
      sortBy: 1300,
    },
    {
      itemId: 15,
      name: "Coffee and sour cherries",
      img: "coffee-sour-cherry.jpg",
      price: 55.0,
      description:
        "I recommend this cake if you like coffee and sour cherries. It’s not sour, it’s not sweet, it’s perfect! No food coloring was added.",
      ingredients:
        "Cream cheese, mascarpone, whipping cream, sour cherries purée, cacao powder, sugar, milk, eggs, butter.",
      per: "kg",
      sortBy: 1400,
    },
    // {
    //   itemId: 16,
    //   name: "Pistachio Sponge with raspberry",
    //   img: "pistachio-sponge.jpg",
    //   price: 60.0,
    //   description:
    //     "Pistachio layers, raspberry confit and a Belgian white chocolate ganache with pistachio paste.",
    //   ingredients:
    //     "Butter, flour, eggs, whipping cream, white chocolate, sugar, cream cheese, pistachio flour, pistachio paste, pistachio, raspberry puree, sugar.",
    //   per: "kg",
    //   sortBy: 1500,
    // },
    {
      itemId: 17,
      name: "Pistachio Raspberry Honey Cake",
      img: "pistachio-honey2.jpg",
      price: 65.0,
      description:
        "This cake is not very sweet and the raspberry insert balances the taste.",
      ingredients:
        "Mascarpone, cream cheese, raspberry purée, all purpose unbleached flour, butter, sugar, whipped cream, sour cream, honey, pistachio paste, eggs, pistachio flour, pistachio, baking soda, pectin NH, salt, citric acid.",
      per: "kg",
      sortBy: 1600,
    },
    // {
    //   itemId: 18,
    //   name: "Mango Honey Cake",
    //   img: "mango-honey-cake1.jpg",
    //   price: 55,
    //   description: "",
    //   ingredients:
    //     "Mango puree, sour cream , butter, sugar, flour, eggs,  honey, cacao powder, cornstarch, vanilla extract, pectin NH, baking soda, baking powder, salt.",
    //   per: "kg",
    //   sortBy: 1700,
    // },
    // {
    //   itemId: 19,
    //   name: "Coffee and Hazelnut",
    //   img: "coffee-hazelnut.jpg",
    //   price: 55,
    //   description: "",
    //   ingredients:
    //     "Whipping cream, sour cream, all purpose unbleached flour, Belgian milk chocolate Callebaut, roasted skinned hazelnuts, eggs, honey, sugar, butter, hazelnut flour, cacao powder Cacao Barry, Baileys liqueur, hazelnut paste, vanilla paste, baking soda, salt, instant coffee.",
    //   per: "kg",
    //   sortBy: 1710,
    // },
    {
      itemId: 20,
      name: "Honey Cake with walnuts and sour cherry",
      img: "walnut-honey.jpeg",
      price: 55,
      description: "",
      ingredients:
        "Flour, whipping cream, sour cream, sugar, eggs, butter, honey, baking soda, vanilla paste.",
      per: "kg",
      sortBy: 1720,
    },
    {
      itemId: 21,
      name: "Chocolate cupcakes",
      img: "chocolate-cupcakes.jpg",
      price: 5,
      description:
        "Chocolate cupcakes filled with a dark/ milk chocolate ganache and decorated with organic fruit.",
      ingredients:
        "Chocolate Callebaut, whipping cream, butter, flour, cacao powder, eggs, sugar, milk.",
      per: "piece",
      sortBy: 1800,
    },
    {
      itemId: 22,
      name: "Vanilla Cupcakes with orange/lemon curd",
      img: "vanilla-cupcakes.jpg",
      price: 5,
      description: "",
      ingredients: "",
      per: "piece",
      sortBy: 1850,
    },

    {
      itemId: 23,
      name: "Tiramisu",
      img: "tiramisu.jpg",
      price: 5,
      description:
        "Italian dessert in a small cup it's always a perfect choice.",
      ingredients:
        "Mascarpone, eggs, cacao powder, whipping cream, sugar, baileys, flour.",
      per: "cup",
      sortBy: 1900,
    },
    {
      itemId: 24,
      name: "Macarons",
      img: "macarons1.jpg",
      price: 3,
      description: "Minimum 16pcs.",
      ingredients: null,
      per: "piece",
      sortBy: 2000,
    },
    {
      itemId: 25,
      name: "Zephyr",
      img: "zefir2.jpg",
      price: 2.5,
      description: "Minimum 10pcs.",
      ingredients: null,
      per: "piece",
      sortBy: 2100,
    },
    {
      itemId: 26,
      name: "Panna Cotta",
      img: "panna-cotta.jpg",
      price: 4.5,
      description: "Minimum 10pcs.",
      ingredients: null,
      per: "piece",
      sortBy: 2200,
    },
    {
      itemId: 27,
      name: "Pavlova",
      img: "pavlova.jpg",
      price: 5,
      description: "Minimum 14pcs.",
      ingredients: null,
      per: "piece",
      sortBy: 2300,
    },
    {
      itemId: 28,
      name: "Kartoshka",
      img: "kartoshka.jpg",
      price: 4.5,
      description: "Minimum 10pcs.",
      ingredients: null,
      per: "piece",
      sortBy: 2300,
    },
    {
      itemId: 29,
      name: "Cake Pops",
      img: "cake-pops.jpg",
      price: 3,
      description: "Minimum 10pcs.",
      ingredients: null,
      per: "piece",
      sortBy: 2300,
    },
  ],
};
