import axios from "axios";

const getAllCakes = async () => {
  return await axios.get("https://e4hwqloa58.execute-api.us-east-1.amazonaws.com/staging/items");
  //return await axios.get("http://127.0.0.1:8000/api/items");
};

export const itemService = {
  getAllCakes
};
